import GeographicZoneSlug from './types/enums/geographicZoneSlug.enum';

export function getGeographicZonePicto({
  geographicZoneSlug,
  countrySlugs
}: {
  geographicZoneSlug: string;
  countrySlugs?: string[];
}) {
  const firstIndexCountrySlug =
    countrySlugs && countrySlugs?.length > 0 ? countrySlugs[0] : undefined;

  switch (geographicZoneSlug) {
    case GeographicZoneSlug.EUROPE:
      return 'picto-europe';

    case GeographicZoneSlug.EAST:
      return 'picto-east';

    case GeographicZoneSlug.AFRICA_AND_INDIAN_OCEAN:
      return 'picto-africa-and-indian-ocean';

    case GeographicZoneSlug.ASIA:
      if (firstIndexCountrySlug === 'chine') return 'picto-china';
      if (firstIndexCountrySlug === 'inde') return 'picto-india';
      if (countrySlugs?.find(countrySlug => countrySlug === 'chine')) return 'picto-china';
      if (countrySlugs?.find(countrySlug => countrySlug === 'inde')) return 'picto-india';
      return 'picto-asia';

    case GeographicZoneSlug.AUSTRALIA_AND_PACIFIC_ISLANDS:
      return 'picto-australia-and-pacific-islands';

    case GeographicZoneSlug.AMERICA:
      if (
        countrySlugs?.find(
          countrySlug => countrySlug === 'canada' || countrySlug.includes('etats-unis')
        )
      )
        return 'picto-north-america';
      return 'picto-south-america';

    default:
      return undefined;
  }
}
